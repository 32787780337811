import React from 'react';
import ScrollReveal from 'scrollreveal';


const MainPageBase = Component => {
  class MainPageBase extends React.Component {
    constructor(props){
      super(props);
    }
    componentDidMount(){
      ScrollReveal().reveal('.reveal', {
        duration: 1500,
        viewFactor: 0.2,
        reset: true,
      });
      ScrollReveal().reveal('.reveal--once', {
        duration: 1500,
        viewFactor: 0.2,
        reset: false,
      });
    }
    render(){
      return <Component {...this.props} />
    }
  }
  return MainPageBase;
}




export default MainPageBase;


import React from 'react';


import Picture from '../common/Picture';
import MainPageBase from './MainPageBase';

import '../../constants/css/MainPage.sass';

import * as ROUTES from '../../constants/routes';

const MainPage = () =>
  <div id='mainpage'>
    <div id='front'>
      <h1 className='title'>Tatsuya Tsubakimoto</h1>
      <nav>
        <a href={ROUTES.HOME} className='selected'>Home</a>
        <a href={ROUTES.WORKS}>Works</a>
      </nav>
      <div className='content--front'>
        <div className='content__left'>
          <div className='image reveal--once'>
            <Picture
              filename={'tsubaki'}
              extention={'jpg'}
              classname={'picture'}
              alt='Tatsuya Tsubakimoto'
            />
          </div>
        </div>
        <div className='content__right'>
        </div>
      </div>
      <svg className='movingsvg' viewBox="0 0 100 100">
        <path id="" d="M -80,80 L 100,0 70,100 -80,100 Z" stroke="" fill="#3280a4" fillOpacity="50%">
        <path id="move_path1" d="M 50,40 L 65,40 Z" stroke="" fill="none"/>
          <animateMotion dur='8s' repeatCount='indefinite'>
            <mpath xlinkHref='#move_path1'></mpath>
          </animateMotion>
        </path>
        <path id="" d="M -10,100 L 50,-80 100,-80 100,100 Z" stroke="" fill="#3280a4" fillOpacity="50%">
        <path id="move_path2" d="M 50,40 L 60,40 Z" stroke="" fill="none"/>
          <animateMotion dur='15s' repeatCount='indefinite'>
            <mpath xlinkHref='#move_path2'></mpath>
          </animateMotion>
        </path>
      </svg>
    </div>
    <main id='contents'>
      <div className='content--standard reveal'>
        <h1>Who I am</h1>
        <p>
          名古屋在住のソフトウェアエンジニア<br/>
          基本ひきこもり。たまにロードバイクに乗ってます。ガンダムはMS08小隊が好き。
        </p>
      </div>
      <div className='content--standard reveal'>
        <h1>Software Engineering</h1>
        <p>
          ウェブフロント周りが好きなフルスタックエンジニア<br/>
          個人的にアプリを作ったりしています。新しいもの好きで、今はReactにハマってます。<br/>
        </p>
      </div>
      <div className='content--standard reveal'>
        <h1>Teaching</h1>
        <p>
          webアプリ作りを学ぶオンライン講座を準備中です。<br/>
          少人数性なので正しく理解しながら進められます。
        </p>
      </div>
    </main>
    <footer>
      ©︎ 2021. TATSUYA TSUBAKIMOTO. ALL RIGHTS RESERVED.
    </footer>
  </div>


export default MainPageBase(MainPage);

import React from 'react';

// CSS
import '../constants/css/App.sass';

// Components
import MainPage from './MainPage';
import WorksPage from './WorksPage';

// React router
import { Switch, Route } from 'react-router-dom';
import * as routes from '../constants/routes';

const App = () =>
    <div className="app">
      <div className='wrapper'>
      <Switch>
        <Route
          exact path={routes.HOME}
          component={() => <MainPage />}
        />
        <Route
          eact path={routes.WORKS}
          component={() => <WorksPage />}
        />
      </Switch>
      </div>
    </div>

export default App;


import React from 'react';

// Components
import Picture from '../common/Picture';
import WorksPageBase from './WorksPageBase';

// SASS
import '../../constants/css/WorksPage.sass';

//Images
import seisankun from '../../resources/images/seisankun.svg';

// Routes
import * as ROUTES from '../../constants/routes';

const WorksPage = () =>
  <div id='workspage'>
    <header id='header'>
        <h1 className='title'>Works</h1>
        <nav>
          <a href={ROUTES.HOME}>Home</a>
          <a href={ROUTES.WORKS} className='selected'>Works</a>
        </nav>
        <svg className='movingsvg' viewBox="0 0 100 100">
          <path id="" d="M 0,50 L 700,0 700,50 Z" stroke="" fill="#3280a4" fillOpacity="50%">
          <path id="move_path1" d="M -150,50 L -50,50 Z" stroke="" fill="none"/>
            <animateMotion dur='8s' repeatCount='indefinite'>
              <mpath xlinkHref='#move_path1'></mpath>
            </animateMotion>
          </path>
          <path id="" d="M 70,0 L 250,0 250,100 0,100 Z" stroke="" fill="#3280a4" fillOpacity="50%">
          <path id="move_path2" d="M 230,0 L 250,0 Z" stroke="" fill="none"/>
            <animateMotion dur='15s' repeatCount='indefinite'>
              <mpath xlinkHref='#move_path2'></mpath>
            </animateMotion>
          </path>
        </svg>
    </header>
    <main id='contents'>
      <div className='content--front reveal--once'>
        <div className='content__left'>
            <div className='image'>
              <img src={seisankun} alt="seisankun"/>
          </div>
        </div>
        <div className='content__right'>
          <div className='text_area'>
            <h1 className='font_seisankun'>精算くん</h1>
            <p>みんなで支払ったお金の精算をお助けするアプリです。無料で使えて友達とリアルタイムにシェアができます。</p>
            <p><a href='https://seisankun.tsubakitech.com'>see more</a></p>
          </div>
        </div>
      </div>
      <div className='content--front reveal--once'>
        <div className='content__left'>
          <div className='text_area'>
            <h1 className='font_veggie'>Veggie Market</h1>
            <p>農家さんから野菜を直接購入できるアプリです。アプリとウェブサイトの作成を担当しました。</p>
          </div>
        </div>
        <div className='content__right'>
          <div className='image'>
            <Picture
              filename={'veggie'}
              extention={'jpg'}
              classname={'picture'}
              alt='Tatsuya Tsubakimoto'
            />
          </div>
        </div>
      </div>
    </main>
    <footer>
      ©︎ 2021. TATSUYA TSUBAKIMOTO. ALL RIGHTS RESERVED.
    </footer>
  </div>


export default WorksPageBase(WorksPage);
